// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-imprint-de-jsx": () => import("./../../../src/pages/imprint.de.jsx" /* webpackChunkName: "component---src-pages-imprint-de-jsx" */),
  "component---src-pages-index-de-jsx": () => import("./../../../src/pages/index.de.jsx" /* webpackChunkName: "component---src-pages-index-de-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-de-jsx": () => import("./../../../src/pages/privacy-policy.de.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-de-jsx" */)
}

